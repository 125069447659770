import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Container from "../components/Container"
import SEO from "../components/SEO"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Img from "gatsby-image"

class About extends React.Component {
  render() {
    const pageData = this.props.data.contentfulPage
    const heading = pageData.heading
    const content = pageData.bodyContent
    const image = pageData.featuredImage

    return (
      <Layout>
        <SEO title="About" description="I am Becky Fitzgerald, a freelance graphic designer currently living in Bristol. I am open to commissions, let me bring your ideas to life!"/>

        <Container>
          <div className="heading">
            {heading
              ? documentToReactComponents(heading.json)
              : `Hello there! It’s really lovely to meet you.`}
          </div>
          {image ? (
            <Img
              className="hero-image"
              alt={image.title}
              fluid={{ ...image.fluid }}
            />
          ) : (
            <div className="hero-image empty"></div>
          )}
          <div className="content">
            {content ? documentToReactComponents(content.json) : ``}
          </div>
        </Container>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query aboutQuery {
    contentfulPage(title: { eq: "About" }) {
      id
      bodyContent {
        json
      }
      heading {
        json
      }
      featuredImage {
        fluid(maxWidth: 1150, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
        title
      }
    }
  }
`
